<template>
  <div class="o2-container">
    <Loader :isLoading="isLoading" />
    <Player :player="player" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Loader from "@/components/layout/Loader";
import Player from "./Player";

export default {
  name: "PlayerContainer",
  components: { Loader, Player },
  computed: {
    ...mapGetters(["isLoading", "player"])
  },
  methods: {
    ...mapActions(["setPlayer"])
  },
  mounted() {
    const player = { id: this.$route.params.id, type: this.$route.params.type };

    this.setPlayer(player);
  }
};
</script>
