<template>
  <div class="o2-player">
    <iframe
      class="o2-embed"
      :src="player.src"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "Player",
  props: {
    player: {
      src: ""
    }
  }
};
</script>

<style scoped>
.o2-player {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  border: 0.2rem solid white;
  border-radius: 1rem;
}

.o2-player .o2-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}
</style>
