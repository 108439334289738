<template>
  <PlayerContainer />
</template>

<script>
import PlayerContainer from "@/components/pages/player/PlayerContainer.vue";

export default {
  name: "PlayerPage",
  components: {
    PlayerContainer
  }
};
</script>
